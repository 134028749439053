import React, { useEffect, useState, useRef, useCallback } from 'react'
import useLocale from '@/hooks/useLocale'
import cn from 'classnames'
import s from './CountryInput.module.scss'
import { SelectArrow, Search, NoData, Close } from '@/icons'

type Props = {
  isError?: boolean
  errMsg?: string
  locale: string
  placeholder?: string
  onChange?: (type: string, value: string) => any
  className?: string
  dataAos?: string
  value?: string
  inputInnerClassName?: string
  required?: boolean
  lable?: string
  onBlur?: (value: any) => void
  errCodeMsg?: string
  phoneCode?: string

}
export interface CountryType {
  abbr: string
  chinese: string
  code: string
  english: string
  spell: string
  state: string
  french: string
  italian: string
  spanish: string
  japanese: string
  russian: string
  germen: string
  label: string
}

const LangMap: Record<string, string> = {
  zh: 'chinese',
  en: 'english',
  ja: 'japanese'
}

export default function CountryInput(props: Props) {
  const { locale, placeholder = '', onChange = null, className = '', dataAos = '', value = '', inputInnerClassName = '', phoneCode = '' } = props
  const { t } = useLocale()
  const [items, setItems] = useState<CountryType[]>([])
  const [code, setCode] = useState(phoneCode)
  const [isActive, setIsActive] = useState(false)
  const overlay = useRef<any>()
  const trigger = useRef<any>()
  const input = useRef<any>()
  const [searchStr, setSearchStr] = useState('')
  const [hover, setHover] = useState(false)

  const loadData = useCallback(async () => {
    const resp = await fetch('/countries.json')
    const list = await resp.json()
    const itemList = list.map((item: any) => {
      return { ...item, label: item[LangMap[locale]] || item['en'] }
    })
    setItems(itemList)
  }, [locale])

  const selectItem = (item: CountryType) => {
    setCode(item.code)
    setIsActive(false)
    setSearchStr('')
    emitChange(item.code, input.current.value)
  }
  const emitChange = (c: string, v: string) => {
    onChange?.(c, v)
  }

  useEffect(() => { setCode(phoneCode) }, [phoneCode])
  const renderItems = items.filter((item: any) => {
    return item?.code?.toLocaleLowerCase().includes(searchStr.toLocaleLowerCase()) || item?.label?.toLocaleLowerCase().includes(searchStr.toLocaleLowerCase())
  })
  useEffect(() => {
    loadData()
    const otherClick = (evt: MouseEvent) => {
      if (trigger.current.contains(evt.target)) {
        return
      }
      if (!overlay.current.contains(evt.target)) {
        setSearchStr('')
        setIsActive(false)
      }
    }
    document.addEventListener('click', otherClick)
    return () => {
      document.removeEventListener('click', otherClick)
    }
  }, [loadData])
  return (
    <div className={cn(s.countryWarpper, className, { [s.countryWarpperHover]: isActive || hover })}>
      {props.lable && (
        <div className={s.lable}>
          {props.required && <span className={s.required}>*&nbsp;</span>}
          {props.lable}
        </div>
      )}
      <div className={cn(s.selectWarpper, inputInnerClassName, { [s.isError]: props.isError })} placeholder={placeholder || ''} data-aos={dataAos}>
        <div
          className={s.icon}
          ref={trigger}
          onClick={() => {
            if (!isActive) {
              setSearchStr('')
            }
            setIsActive(() => !isActive)
          }}
        >
          {code ? <span className={s.countryCode}>{`+${code}`}</span> : <span className={s.countryCode_select}>{t('Please Select')}</span>}
          {/* <SelectArrow className={cn(s.arrow, { [s.rotate]: isActive })} /> */}
          <div className={cn(s.arrowWarpper)}>
            <SelectArrow width={8} height={6} className={cn(s.arrow, { [s.rotate]: isActive })} />
          </div>
        </div>
        {/* <div className={s.line} /> */}
        <div className={s.inputContent}>
          <input
            value={value}
            type="number"
            ref={input}
            placeholder={placeholder}
            onChange={(evt) => emitChange(code, evt.target.value)}
            onFocus={() => {
              setHover(true)
            }}
            onBlur={(e) => {
              setHover(false)
              props?.onBlur && props?.onBlur(e.target.value)
            }}
          />
          {value && (
            <Close
              className={s.close}
              onClick={(e: any) => {
                e.stopPropagation && e.stopPropagation()
                emitChange(code, '')
              }}
            />
          )}
        </div>
      </div>
      <div className={cn(s.overlay, { [s.show]: isActive })} ref={overlay}>
        <div className={s.search}>
          <input type="text" placeholder={t('Search')} value={searchStr} onChange={(evt) => setSearchStr(evt.target.value)} />
          {searchStr ? (
            <Close
              className={s.close}
              onClick={(e: any) => {
                e.stopPropagation && e.stopPropagation()
                setSearchStr('')
              }}
            />
          ) : (
            <Search width={14} height={14} className={cn(s.arrow)} />
          )}
        </div>

        <div className={cn(s.list, 'scrollbar')} data-lenis-prevent>
          {renderItems.length > 0 ? (
            renderItems.map((item) => (
              <div className={cn(s.selectItem, { [s.active]: code === item.code })} key={item.english} onClick={() => selectItem(item)}>
                <span>{item.label}</span>&nbsp;
                <span className={s.textBold}>+{item.code}</span>
              </div>
            ))
          ) : (
            <div className={s.noDataWarpper}>
              <NoData width={114} height={69} />
              <span> {t('no data')}</span>
            </div>
          )}
        </div>
      </div>
      <div className={cn(s.error, { [s.isErrorText]: props.isError || props?.errCodeMsg })}>{props.errMsg || props?.errCodeMsg}</div>
    </div>
  )
}
