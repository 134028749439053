import React from 'react'
import styles from './Input.module.scss'
import classnames from 'classnames'
import { Close } from '@/icons'
import { Tip } from '@/icons'
import { Tooltip } from 'react-tooltip'

import useLocale from '@/hooks/useLocale'



interface IProps {
  errorStatic?: boolean
  value: string
  onChange?: (value: string) => void
  onKeyDown?: (value: any) => void
  onBlur?: (value: any) => void
  lable?: string
  className?: string
  isError?: boolean
  errMsg?: string
  inputInnerClassName?: string
  dataAos?: string
  required?: boolean
  placeholder?: string
  disabled?: boolean
  tip?: boolean
}


const Input = (props: IProps) => {
  const { isError = false, errorStatic = false, tip = false } = props
  const { t } = useLocale()

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    props?.onChange && props?.onChange(e.target.value)
  }

  return (
    <div className={classnames(styles.inputContainer, props.className, { [styles.inputContainerDisabled]: props.disabled })}>
      {props.lable && (
        <div className={classnames(styles.lable, { [styles.lable_center]: tip })}>
          {props.required && <span className={styles.required}>*&nbsp;</span>}
          {props.lable}
          {tip && <>
            <Tip className={styles.tip_image} width={5} id="CorporateEmail">
            </Tip>
            <Tooltip
              clickable={true}

              className={styles.tip} anchorSelect="#CorporateEmail" place={'right'} render={() => (
                <div
                  className='text3'
                  dangerouslySetInnerHTML={{ __html: t("If you are using a personal email address, please send your request to <a href='mailto:info@chainup.com'>info@chainup.com</a>") }}></div>
              )}>
            </Tooltip>
          </>

          }


        </div>
      )}
      <div className={classnames(styles.inputContent, { [styles.inputContentError]: isError })}>
        <input
          disabled={props?.disabled}
          className={classnames(styles.inputInner, props.inputInnerClassName)}
          placeholder={props.placeholder}
          type="text"
          value={props.value}
          onChange={handleInput}
          onKeyDown={(e: any) => props?.onKeyDown && props?.onKeyDown(e)}
          onBlur={(e: any) => {
            props?.onBlur && props?.onBlur(e.target.value)
          }}
        />
        {props.value && (
          <Close
            className={styles.close}
            onClick={() => {
              props?.onChange && props?.onChange('')
            }}
          />
        )}
      </div>

      <div className={classnames(styles.error, { [styles.errorStatic]: errorStatic && !props.errMsg })}>{props.errMsg}</div>
    </div>
  )
}

export default Input
