import qs from 'qs'

export default async function request(url: string, method = 'get', params?: Record<string, any>, data?: Record<string, any>) {
  let root = 'https://website-api.chainup.com'
  if (process.env.NEXT_PUBLIC_DOMAIN_ENV === 'production') {
    //生产环境接口地址
    root = 'https://website-api.chainup.com'
  } else {
    // root = 'https://website-api.chainup.com'
    //开发以及测试环境接口地址(我这里开发环境和测试环境是一样的接口)
    root = 'http://website-api.taobao.com'
    // root = 'https://website-api.chainup.com'
  }
  const qsStr = params ? '?' + qs.stringify(params || {}) : ''
  const requestUrl = root + url + qsStr
  const headers = { 'Content-Type': 'application/json' }
  const dataStr = method !== 'get' && data ? JSON.stringify(data) : undefined
  const resp = await fetch(requestUrl, { headers, method, body: dataStr })
  return await resp.json()
}
